import React from 'react';
import { MenuLayout } from '../../components/menu-layout.component';
import { Footer } from '../../components/footer.component';

export default function Asct() {
    return (
        <MenuLayout disableSecureLayout={true} isAbs={true} className="homepage transparent">
            <section className="docs">
                <div className="container">
                    <h1>豊泉家PCR検査センター</h1>
                    <h2 className="t-heading1 docs__heading t-center">特定商取引法に基づく表記</h2>
                    <div className="docs__block">
                        <p>販売業者：一般財団法人 SF豊泉家　豊泉家PCR検査センター</p>
                        <p><br /> 運営統括責任者名：河本　真</p>
                        <p><br /> 所在地：〒561-0881　大阪府豊中市中桜塚3-2-1　豊泉家新予防センター</p>
                        <p><br /> 電話番号：0120-294-998</p>
                        <p>メールアドレス：<a href="mailto:pcr-center@e-housenka.com">pcr-center@e-housenka.com</a></p>
                        <p><br /> 販売価格：各商品ページをご参照ください。</p>
                        <p>商品代金以外の必要料金：販売価格とは別に配送料、代引き手数料、振込手数料がかかる場合もございます。</p>
                        <p>お支払い方法：銀行振込、クレジットカード、代引き決済</p>
                        <p>～振込先口座情報～</p>
                        <p>りそな銀行</p>
                        <p>堂島支店（店番：104）</p>
                        <p>口座番号　0129619</p>
                        <p>口座名義　一般財団法人　SF豊泉家</p>
                        <p>お支払い期限：ご注文後7日以内といたします。ご注文後７日間ご入金がない場合は、購入の意思がないものとし、注文を自動的にキャンセルとさせていただきます。&nbsp;</p>
                        <p>商品の引渡時期：ご注文を受けてから7日以内に発送いたします。</p>
                        <p><strong><br /></strong>商品の引渡方法：佐川急便【返送用レターパック込み】</p>
                        <p>返品・不良品・キャンセルについて：商品到着後速やかにご連絡ください。商品に欠陥がある場合を除き、返品には応じかねますのでご了承ください。</p>
                        <p><br /> 返品期限：商品到着後７日以内とさせていただきます。</p>
                        <p>返品送料：お客様都合による返品につきましてはお客様のご負担とさせていただきます。不良品に該当する場合は当方で負担いたします。</p>
                    </div>
                </div>
            </section>
            <Footer />
        </MenuLayout>
    );
}
